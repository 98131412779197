import { patchResp } from '../shared/util/gql-util'

const initialState = {
    events: [],
    queryEventsState: 0,
    patchEventState: 0,
    queryEventError: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'QUERY_EVENT_VIA_EVENT_LINK_PENDING': {
            return { ...state, queryEventsState: 1 }
        }

        case 'QUERY_EVENT_VIA_EVENT_LINK_FULFILLED': {
            return { ...state, queryEventsState: 2, events: action.payload.queryViaEventLink }
        }

        case 'QUERY_EVENT_VIA_EVENT_LINK_REJECTED': {
            return { ...state, queryEventsState: 3, queryEventError: action.payload }
        }

        case 'PATCH_EVENT_VIA_EVENT_LINK_PENDING': {
            return { ...state, patchEventState: 1 }
        }

        case 'PATCH_EVENT_VIA_EVENT_LINK_FULFILLED': {
            return { ...state, patchEventState: 2, events: patchResp(state.events, action.payload.patchViaEventLink) }
        }

        case 'PATCH_EVENT_VIA_EVENT_LINK_REJECTED': {
            return { ...state, patchEventState: 3, queryEventError: action.payload }
        }

        default:
            return state
    }
}

export default reducer
