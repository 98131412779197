import { get } from 'lodash'
import { getToken } from './shared/util/storage-util'

export let url = {}
if (process.env.REACT_APP_ENV === 'dev') {
    url = {
        BASE_URL: 'https://test.ishift.ai',
        protocol: 'https:',
        hostname: 'test.ishift.ai'
    }
} else {
    url = {
        BASE_URL: window.location.origin,
        protocol: window.location.protocol,
        hostname: window.location.hostname
    }
}
const URL = `${url.BASE_URL.toString()}/core/graphql/http/v1.0.0`

const post = async (query, variables) => {
    try {
        const token = getToken() //:TODO move to outside

        const result = await fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                query,
                variables
            }),
        })

        const json = await result.json()

        const data = get(json, 'data') || {}
        const errors = get(json, 'errors') || []

        if (errors.length > 0) {
            throw errors.map(err => {
                const msg = get(err, 'message', ':')
                const arr = msg.split(':')

                if (arr.length === 1) {
                    return {
                        message: arr[0]
                    }
                }

                return {
                    code: arr[0],
                    message: arr[1]
                }
            })
        }

        return data
    } catch (e) {
        if (e instanceof Array) throw e

        throw [{ message: e.message }]
    }
}

export default post
