import {cloneDeep, get} from 'lodash'
import {v4 as uuid} from 'uuid'
import {optionsToString, stringToOptions} from '../shared/util/form-util'

const initialState = {
    createForm: {},
    queryFormState: 0,
    patchFormState: 0,
    createFormState: 0,
    queryForms: {},
    queryFormsState: 0,
    shareFormId: '',
    shareFormState: 0,
    clientForm: {},
    loadFormState: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_FORM_PENDING': {
            return {...state, createFormState: 1}
        }

        case 'CREATE_FORM_FULFILLED': {
            return {...state, createFormState: 2, createForm: action.payload.createForm}
        }

        case 'CREATE_FORM_REJECTED': {
            return {...state, createFormState: 3}
        }

        case 'QUERY_FORM_BY_ID_PENDING': {
            return {...state, queryFormState: 1}
        }

        case 'QUERY_FORM_BY_ID_FULFILLED': {
            return {...state, queryFormState: 2, createForm: get(action.payload, 'queryForm[0]', {})}
        }

        case 'QUERY_FORM_BY_ID_REJECTED': {
            return {...state, queryFormState: 3}
        }

        case 'QUERY_FORMS_PENDING': {
            return {...state, queryFormsState: 1}
        }

        case 'QUERY_FORMS_FULFILLED': {
            return {...state, queryFormsState: 2, queryForms: action.payload.queryForm}
        }

        case 'QUERY_FORMS_REJECTED': {
            return {...state, queryFormsState: 3}
        }

        case 'PATCH_CREATE_FORM_HEADING_PENDING': {
            return {...state, patchFormState: 1}
        }

        case 'PATCH_CREATE_FORM_HEADING_FULFILLED': {
            return {...state, patchFormState: 2}
        }

        case 'PATCH_CREATE_FORM_HEADING_REJECTED': {
            return {...state, patchFormState: 3}
        }

        case 'PATCH_FORM_ELEMENTS_PENDING': {
            return {...state, patchFormState: 1}
        }

        case 'PATCH_FORM_ELEMENTS_FULFILLED': {
            return {
                ...state, patchFormState: 2,
                // createForm: action.payload.patchForm
            }
        }

        case 'PATCH_FORM_ELEMENTS_REJECTED': {
            return {...state, patchFormState: 3}
        }

        case 'HANDLE_CREATE_FORM_CHANGE': {
            const {path, payload} = action

            const createForm = cloneDeep(state.createForm)
            createForm[path] = payload

            return {...state, createForm}
        }

        //handleOption(display, value, optionIndex, questionIndex) {
        //         const formSchema = cloneDeep(this.state.formSchema)
        //         const option = formSchema[questionIndex].options[optionIndex]
        //
        //         if (display) option.display = display
        //         if (value) option.value = value
        //
        //         formSchema[questionIndex].options[optionIndex] = option
        //
        //         this.setState({ formSchema })
        //     }

        // Question
        case 'ADD_QUESTION': {
            const createForm = cloneDeep(state.createForm)
            const elements = get(createForm, 'elements', [])

            elements.push({
                id: uuid(),
                type: 'TEXT_BOX',
                required: false,
                question: {
                    label: ''
                }
            })

            createForm.elements = elements

            return {...state, createForm}
        }

        case 'ADD_QUESTION_HERE': {
            const {questionIndex} = action
            const createForm = cloneDeep(state.createForm)
            const elements = get(createForm, 'elements', [])

            const question = {
                id: uuid(),
                type: 'TEXT_BOX',
                required: false,
                question: {
                    label: 'Untitled question?'
                }
            }
            elements.splice(questionIndex + 1, 0, question)

            createForm.elements = elements

            return {...state, createForm}
        }

        case 'COPY_QUESTION': {
            const {questionIndex} = action

            const createForm = cloneDeep(state.createForm)
            const elements = get(createForm, 'elements', [])
            const question = cloneDeep(elements[questionIndex])
            question.id = uuid()

            elements.splice(questionIndex, 0, question)

            createForm.elements = elements

            return {...state, createForm}
        }

        case 'REMOVE_QUESTION': {
            const {questionIndex} = action

            const createForm = cloneDeep(state.createForm)
            const elements = get(createForm, 'elements', [])

            elements.splice(questionIndex, 1)

            createForm.elements = elements

            return {...state, createForm}
        }

        // Options
        case 'HANDLE_OPTIONS_CHANGE': {
            const {payload, optionIndex, questionIndex} = action

            const createForm = cloneDeep(state.createForm)
            const options = stringToOptions(get(createForm, ['elements', questionIndex]))

            const option = options[optionIndex]
            option.display = payload
            option.value = payload
            options[optionIndex] = option

            const data = optionsToString(options)

            createForm.elements[questionIndex].data = []
            createForm.elements[questionIndex].data.push({data})

            return {...state, createForm}
        }

        case 'ADD_OPTION': {
            const {optionIndex, questionIndex} = action

            const createForm = cloneDeep(state.createForm)
            const data = get(createForm, ['elements', questionIndex, 'data', [0], 'data'], [])
            data.push(`Default Option ${optionIndex+1}|Default Option ${optionIndex+1}|false`)

            createForm.elements[questionIndex].data = []
            createForm.elements[questionIndex].data.push({data})

            return {...state, createForm}
        }

        case 'REMOVE_OPTION': {
            const {optionIndex, questionIndex} = action

            const createForm = cloneDeep(state.createForm)
            const data = get(createForm, ['elements', questionIndex, 'data', [0], 'data'])

            data.splice(optionIndex, 1)

            createForm.elements[questionIndex].data = []
            createForm.elements[questionIndex].data.push({data})

            return {...state, createForm}
        }

        case 'HANDLE_QUESTION_LABEL': {
            const {index, payload} = action

            const createForm = cloneDeep(state.createForm)
            createForm.elements[index].question.label = payload

            return {...state, createForm}
        }

        case 'HANDLE_QUESTION_REQUIRED': {
            const {index, payload} = action

            const createForm = cloneDeep(state.createForm)
            createForm.elements[index].required = payload

            return {...state, createForm}
        }

        case 'HANDLE_QUESTION_TYPE': {
            const {index, payload} = action

            const createForm = cloneDeep(state.createForm)
            createForm.elements[index].tags = []
            createForm.elements[index].type = payload

            return {...state, createForm}
        }

        case 'HANDLE_QUESTION_REMINDER': {
            const {index, payload} = action

            const createForm = cloneDeep(state.createForm)
            createForm.elements[index].tags = payload.split()
            return {...state, createForm}
        }

        case 'CLEAR_STATE': {
            return {...state, createFormState: 0, queryFormState: 0}
        }

        case 'NOT_MATCH_PASSWORD': {
            return {...state, completeRegUserError: action.payload, completeRegUserState: 3}
        }

        // share form
        case 'SHARE_FORM_PENDING': {
            return {...state, shareFormState: 1}
        }
        case 'SHARE_FORM_FULFILLED': {
            return {...state, shareFormState: 2, shareFormId: action.payload.shareForm}
        }
        case 'SET_NAME': {
            return {...state, name: action.payload}
        }
        default:
            return state
    }
}

export default reducer
