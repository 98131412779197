import * as source from './source'

export function forgotPassword(forgotPasswordReq) {
    return function (dispatch) {
        dispatch({
            type: 'FORGOT_PASSWORD',
            payload: source.forgotPassword(forgotPasswordReq)
        })
    }
}

export function updateUserProfile(updateUserProfileReq) {
    return function (dispatch) {
        dispatch({
            type: 'UPDATE_USER_PROFILE',
            payload: source.updateUserProfile(updateUserProfileReq)
        })
    }
}

export function deleteUserProfile() {
    return function (dispatch) {
        dispatch({
            type: 'DELETE_USER_PROFILE',
            payload: source.deleteUserProfile()
        })
    }
}

export function setNotMatchPassword(data) {
    return {
        type: 'NOT_MATCH_PASSWORD',
        payload: data
    }
}

export function setUserDetails(data) {
    return { // handle dispatch
        type: 'SET_USER_DETAILS',
        payload: data
    }
}

//
// export function setNotMatchPassword (data) {
//     return {
//         type: 'NOT_MATCH_PASSWORD',
//         payload: data
//     }
// }
