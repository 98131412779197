import { get, camelCase, groupBy, keyBy } from 'lodash'
import { cloneDeep } from 'lodash'

const injectExternalRef = (resp = {}, selector = 'id') => {
    const externalRefs = get(resp, '__externalRef', [])

    const types = groupBy(externalRefs, '__typename')

    Object.keys(types).forEach(key => {
        resp[`${camelCase(key)}s`] = keyBy(types[key], selector)
    })

    return resp
}

export const parseObjResp = (resp) => {
    return injectExternalRef(resp)
}

export const parseArrResp = (respArr) => {
    return respArr.map(resp => parseResp(resp))
}

const parseResp = (resp) => {
    if (resp instanceof Array) {
        return parseArrResp(resp)
    } else {
        return parseObjResp(resp)
    }
}

export const patchResp = (queryResp = [], patchResp) => {
    const arr = cloneDeep(queryResp)

    const index = arr.findIndex(ev => ev.id === patchResp.id)

    if (index !== -1) {
        arr[index] = patchResp
    }

    return arr
}

export default parseResp
