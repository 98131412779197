export const storeToken = (token, rememberUser = false) => {
    sessionStorage.setItem('token', token)
    localStorage.setItem('token', token)

    if (rememberUser) localStorage.setItem('token', token)
}

export const storeUser = (user) => {
    sessionStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('user', JSON.stringify(user))
}

export const getToken = () => {
    let token = sessionStorage.getItem('token')
    const localToken = localStorage.getItem('token')

    if (localToken) token = localToken

    return token
}

export const getUser = () => { // promise
    let user = sessionStorage.getItem('user')
    const localUser = localStorage.getItem('user')

    if (localUser) user = localUser

    return (user)
}

export const storeView = (view) => {
    sessionStorage.setItem('view', view)
}

export const getView = () => {
    return sessionStorage.getItem('view') || 'MONTH'
}

export const storeEmail = (email) => {
    sessionStorage.setItem('email', email)
}

export const getEmail = (email) => {
    return sessionStorage.getItem('email')
}

export const storePassword = (password) => {
    sessionStorage.setItem('password', password)
}

export const getPassword = () => {
    return sessionStorage.getItem('password')
}

export const removeLoginData = () => {
    sessionStorage.removeItem('password')
    sessionStorage.removeItem('email')
}

export const clearToken = () => {
    sessionStorage.clear()
    localStorage.clear()
}
