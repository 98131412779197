import { get } from 'lodash'

export const localTypeMap = {
    TEXT_BOX: 'text',
    SINGLE_SELECT: 'react_select',
    RADIO_BUTTON: 'radio',
    CHECK_BOX: 'checkbox',
    DATE_PICKER: 'datepicker'
}

export const stringToOptions = (item) => {
    const data = get(item, 'data.[0].data', [])

    return data.map(d => {
        const arr = d.split('|')

        return {
            value: arr[0],
            display: arr[1],
            checked: JSON.parse(arr[2])
        }
    })
}

export const optionsToString = (options = [], separator = '|') => {
    return options.map(option => {
        const arr = [
            option.value,
            option.display,
            option.checked
        ]

        return arr.join(separator)
    })
}
