import { cloneDeep } from 'lodash/lang'

const initialState = {
    events: [],
    monthEvents: [],
    queryEventState: 0,
    patchEventState: 0,
    queryEventError: [],
    eventLink: {},
    createEventLinkState: 0,
    eventLinkError: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'QUERY_EVENT_PENDING': {
            return { ...state, queryEventState: 1 }
        }

        case 'QUERY_EVENT_FULFILLED': {
            return { ...state, queryEventState: 2, events: action.payload.queryEvent }
        }

        case 'QUERY_EVENT_REJECTED': {
            return { ...state, queryEventState: 3, queryEventError: action.payload }
        }

        case 'PATCH_EVENT_PENDING': {
            return { ...state, patchEventState: 1 }
        }

        case 'PATCH_EVENT_FULFILLED': {
            return { ...state, patchEventState: 2, events: patchEvent(state.events, action.payload.patchEvent) }
        }

        case 'PATCH_EVENT_REJECTED': {
            return { ...state, patchEventState: 3, queryEventError: action.payload }
        }

        case 'CREATE_EVENT_LINK_PENDING': {
            return { ...state, createEventLinkState: 1 }
        }

        case 'CREATE_EVENT_LINK_FULFILLED': {
            return { ...state, createEventLinkState: 2, eventLink: action.payload.createEventLink }
        }

        case 'CREATE_EVENT_LINK_REJECTED': {
            return { ...state, createEventLinkState: 3, eventLinkError: action.payload }
        }

        default:
            return state
    }
}

export default reducer

const patchEvent = (oldEvents = [], event) => {
    const events = cloneDeep(oldEvents)

    const index = events.findIndex(ev => ev.id === event.id)

    if (index !== -1) {
        events[index] = event
    }

    return events
}
