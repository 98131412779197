import parseResp, { parseObjResp } from '../shared/util/gql-util'

const initialState = {
    contact: {},
    queryContactByIdState: 0,
    queryContactState: 0,
    queryContactError: [],
    queryContactRes: {},
    bulkCreateContactState: 0,
    bulkCreateContactLength: 0,
    requestBulkContactLength: 0,
    createContactState: 0,
    patchContactState: 0,
    createContactError: [],
    createContactRes: {},
    modelFollow: 'CREATE',
    selectedContact: null,
    selectedForm: null,
    contactModalUpdateState: 1,
    associatedForms: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'QUERY_CONTACT_PENDING': {
            return { ...state, queryContactState: 1 }
        }

        case 'QUERY_CONTACT_FULFILLED': {
            const queryContactRes = parseResp(action.payload.queryContact)

            return { ...state, queryContactState: 2, queryContactRes }
        }

        case 'QUERY_CONTACT_REJECTED': {
            return { ...state, queryContactState: 3, queryContactError: action.payload }
        }

        case 'QUERY_CONTACT_BY_ID_PENDING': {
            return { ...state, queryContactByIdState: 1 }
        }

        case 'QUERY_CONTACT_BY_ID_FULFILLED': {
            const contact = parseObjResp(action.payload.queryContact[0] || {})

            return { ...state, queryContactByIdState: 2, contact }
        }

        case 'QUERY_CONTACT_BY_ID_REJECTED': {
            return { ...state, queryContactByIdState: 3, queryContactError: action.payload }
        }

        case 'BULK_CREATE_CONTACT_PENDING': {
            return { ...state, bulkCreateContactState: 1, bulkCreateContactLength: 0 }
        }

        case 'BULK_CREATE_CONTACT_FULFILLED': {
            return { ...state, bulkCreateContactState: 2, bulkCreateContactLength: action.payload.bulkCreateContact }
        }

        case 'BULK_CREATE_CONTACT_REJECTED': {
            return { ...state, bulkCreateContactState: 3, queryContactError: action.payload }
        }

        case 'CREATE_CONTACT_PENDING': {
            return { ...state, createContactState: 1 }
        }

        case 'CREATE_CONTACT_FULFILLED': {
            return { ...state, createContactState: 2, createContactRes: action.payload.createContact }
        }

        case 'CREATE_CONTACT_REJECTED': {
            return { ...state, createContactState: 3, createContactError: action.payload }
        }

        case 'PATCH_CONTACT_PENDING': {
            return { ...state, patchContactState: 1 }
        }

        case 'PATCH_CONTACT_FULFILLED': {
            return { ...state, patchContactState: 2 }
        }

        case 'PATCH_CONTACT_REJECTED': {
            return { ...state, patchContactState: 3, createContactError: action.payload }
        }

        case 'SET_SELECTED_CONTACT': {
            return { ...state, selectedContact: action.payload }
        }

        case 'SET_SELECTED_FORM': {
            return { ...state, selectedForm: action.payload }
        }

        case 'SET_ASSOCIATED_FORMS': {
            return { ...state, associatedForms: action.payload }
        }

        case 'SET_MODEL_FOLLOW': {
            return { ...state, modelFollow: action.payload }
        }

        case 'SET_REQUEST_BULK_CREATE_CONTACT' : {
            return { ...state, requestBulkContactLength: action.payload }
        }
        default:
            return state
    }
}

export default reducer
