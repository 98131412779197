import { clearToken, storeUser } from '../shared/util/storage-util'
import { queryUser } from './source'

const initialState = {
    forgotPasswordState: 0,
    forgotPasswordError: [],
    forgotPasswordReq: {},
    forgotPasswordRes: '',
    queryUserState: 0,
    queryUserError: [],
    updateUserProfileState: 0,
    deleteUserProfileState: 0,
    updateUserProfileError: [],
    userDetails: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_DETAILS': {
            return { ...state, userDetails: action.payload }
        }

        case 'FORGOT_PASSWORD_PENDING': {
            return { ...state, forgotPasswordState: 1 }
        }

        case 'FORGOT_PASSWORD_FULFILLED': {
            clearToken()
            return { ...state, forgotPasswordState: 2, forgotPasswordRes: action.payload }
        }

        case 'FORGOT_PASSWORD_REJECTED': {
            return { ...state, forgotPasswordState: 3, forgotPasswordError: action.payload }
        }

        case 'NOT_MATCH_PASSWORD': {
            return { ...state, forgotPasswordError: action.payload, forgotPasswordState: 3 }
        }

        case 'UPDATE_USER_PROFILE_PENDING': {
            return { ...state, updateUserProfileState: 1 }
        }

        case 'UPDATE_USER_PROFILE_FULFILLED': {
            const user = action.payload.updateUserProfile
            storeUser(user)
            return { ...state, updateUserProfileState: 2, userDetails: action.payload.updateUserProfile }
        }

        case 'UPDATE_USER_PROFILE_REJECTED': {
            return { ...state, updateUserProfileState: 3, updateUserProfileError: action.payload }
        }

        case 'DELETE_USER_PROFILE_PENDING': {
            return { ...state, deleteUserProfileState: 1 }
        }

        case 'DELETE_USER_PROFILE_FULFILLED': {
            return { ...state, deleteUserProfileState: 2 }
        }

        case 'DELETE_USER_PROFILE_REJECTED': {
            return { ...state, deleteUserProfileState: 3 }
        }

        default:
            return state
    }
}

export default reducer
