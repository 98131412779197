import React, {Component, lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import Loader from '../shared/components/loaders/Loader'
import {connect} from 'react-redux'
import withRouteUtil from '../shared/util/route-util'
import {ToastContainer} from 'react-toastify'
import {getToken, getUser} from "../shared/util/storage-util";
import {setUserDetails} from "../setting/actions";

const LandingPage = lazy(() => import('../public-page/component/LandingPage'))
const PrivacyPolicy = lazy(() => import('../public-page/component/PrivacyPolicy'))
const Terms = lazy(() => import('../public-page/component/Terms'))
const AuthRoutes = lazy(() => import('../auth/routes'))
const AppRoutes = lazy(() => import('../routes'))
const ClientFormRoutes = lazy(() => import('../client-form/routes'))
const ExternalTodoRoutes = lazy(() => import('../external-todo/routes'))
const SettingRoutes = lazy(() => import('../setting/routes'))

class App extends Component {
    state = {
        token: getToken(),
        user: getUser()
    }

    componentDidMount() {
        if (this.state.token) {
            this.props.dispatch(setUserDetails(JSON.parse(this.state.user))) // promise
        }
    }

    render() {

        return (
            <React.Suspense fallback={<Loader/>}>
                <Routes>
                    <Route path="/" element={<LandingPage/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/terms-and-condition" element={<Terms/>}/>
                    <Route path="/app/*" element={<AppRoutes/>}/>
                    <Route path="/auth/*" element={<AuthRoutes/>}/>
                    <Route path="/form/*" element={<ClientFormRoutes/>}/>
                    <Route path="/external-todo/*" element={<ExternalTodoRoutes/>}/>
                    <Route path="/setting/*" element={<SettingRoutes/>}/>
                    <Route path="*" element={<Navigate to="/app" replace/>}/>
                </Routes>

                <ToastContainer/>
            </React.Suspense>
        )
    }
}

export default connect(state => {
    return {
        settingReducer: state.settingReducer
    }
})(withRouteUtil(App))
