import {cloneDeep} from "lodash";

export const trimObj = (data = {}) => {
    let trimObj = {};
    Object.keys(data).map(k => {
            const reqClone = cloneDeep(trimObj)
            reqClone[k] = data[k].trim()
            trimObj = reqClone
        }
    )
    return trimObj;
}