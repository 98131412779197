import post from '../request'
import { deleteUserProfileQuery, forgotPasswordQuery, updateUserProfileQuery } from './query'
import {trimObj} from "../shared/util/object-util";

export const forgotPassword = async (forgotPasswordReq) => {
    return await post(forgotPasswordQuery, { forgotPasswordReq })
}

export const updateUserProfile = async (data) => {
    const updateUserProfileReq =  trimObj(data)
    return await post(updateUserProfileQuery, { updateUserProfileReq })
}

export const deleteUserProfile = async () => {
    return await post(deleteUserProfileQuery, {})
}
