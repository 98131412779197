const initialState = {
    clientForm: {},
    loadFormState: 0,
    submitFormId: {},
    submitFormState: 0,
    submission: {},
    patchSubmissionState: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOAD_FORM_PENDING': {
            return { ...state, loadFormState: 1 }
        }

        case 'LOAD_FORM_FULFILLED': {
            return { ...state, loadFormState: 2, clientForm: action.payload.loadForm }
        }

        case 'LOAD_FORM_REJECTED': {
            return { ...state, loadFormState: 3 }
        }

        case 'SUBMIT_FORM_PENDING': {
            return { ...state, submitFormState: 1 }
        }

        case 'SUBMIT_FORM_FULFILLED': {
            return { ...state, submitFormState: 2, submitFormId: action.payload.submitForm }
        }

        case 'SUBMIT_FORM_REJECTED': {
            return { ...state, submitFormState: 3 }
        }

        case 'PATCH_SUBMISSION_PENDING': {
            return { ...state, patchSubmissionState: 1 }
        }

        case 'PATCH_SUBMISSION_FULFILLED': {
            return { ...state, patchSubmissionState: 2, submission: action.payload.patchSubmission }
        }

        case 'PATCH_SUBMISSION_REJECTED': {
            return { ...state, patchSubmissionState: 3 }
        }

        default:
            return state
    }
}

export default reducer
