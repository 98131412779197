import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import homeReducer from './home/reducer'
import externalTodoReducer from './external-todo/reducer'
import authReducer from './auth/reducer'
import clientFormReducer from './client-form/reducer'
import formReducer from './form/reducer'
import settingReducer from './setting/reducer'
import contactReducer from './contact/reducer'

export default createStore(
    combineReducers({
        homeReducer,
        externalTodoReducer,
        authReducer,
        formReducer,
        clientFormReducer,
        settingReducer,
        contactReducer
    }),
    applyMiddleware(promise, thunk, createLogger())
)
