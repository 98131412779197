export const forgotPasswordQuery = `
mutation ChangePasswordUser($forgotPasswordReq : ChangePasswordReqInput){
  changePasswordUser(request:$forgotPasswordReq)
}
`


export const updateUserProfileQuery = `
mutation updateUserProfile($updateUserProfileReq : UserProfileUpdateReqInput){
  updateUserProfile(request : $updateUserProfileReq){
    id,
    department,
    firstName,
    username,
    lastName
    signupMode
  }
}`

export const deleteUserProfileQuery = `
    mutation DeleteUserProfile {
        deleteUserProfile
    }
`
