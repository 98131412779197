import { storeToken, storeUser } from '../shared/util/storage-util'

const initialState = {
    loginState: 0,
    loginError: [],
    authMode: 'LOGIN', // REGISTER
    loginReq: {},
    beginRegUserReq: {},
    beginRegUserState: 0,
    beginRegUserError: [],
    beginRegUserRes: '',
    rememberMe: false,
    verifyRegUserReq: {},
    verifyRegUserState: 0,
    verifyRegUserError: [],
    verifyRegUserRes: '',
    userProfile: {},
    completeRegUserError: [],
    completeRegUserState: 0,
    completeRegUserRes: '',
    authFollow: '',
    beginResetPasswordState: 0,
    beginResetPasswordError: [],
    beginResetPasswordReq: {},
    beginResetPasswordRes: '',
    optCode: '',
    completeResetPasswordState: 0,
    completeResetPasswordError: [],
    completeResetPasswordReq: {},
    completeResetPasswordRes: '',
    otpVerifyResetPasswordState: 0,
    otpVerifyResetPasswordError: [],
    otpVerifyResetPasswordReq:{},
    otpVerifyResetPasswordRes: '',
    loginUserError: [],
    loginGoogleUserRes: '',
    loginFacebookUserRes: '',
    oAuthLoginState: 0,
    checkUsername: '',
    checkUsernameState: 0,
    checkUsernameError: [],
    queryUserState : 0,
    queryUserError: [],
    userDetails:{}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'LOGIN_PENDING': {
            return { ...state, loginState: 1 }
        }

        case 'LOGIN_FULFILLED': {
            const token = action.payload.loginUser
            storeToken(token, state.rememberMe)

            return { ...state, loginState: 2 }
        }

        case 'LOGIN_REJECTED': {
            return { ...state, loginState: 3, loginError: action.payload }
        }

        case 'QUERY_USER_PENDING': {
            return { ...state, queryUserState: 1 }
        }

        case 'QUERY_USER_FULFILLED': {
            const user = action.payload.queryUserProfile
            storeUser(user)
            return { ...state, queryUserState: 2, userDetails: action.payload.queryUserProfile }
        }

        case 'QUERY_USER_REJECTED': {
            return { ...state, queryUserState: 3, queryUserError: action.payload }
        }

        case 'BEGIN_REG_USER_PENDING': {
            return { ...state, beginRegUserState: 1 }
        }

        case 'BEGIN_REG_USER_FULFILLED': {
            return { ...state, beginRegUserState: 2, beginRegUserRes: action.payload.beginRegUser }
        }

        case 'BEGIN_REG_USER_REJECTED': {
            return { ...state, beginRegUserState: 3, beginRegUserError: action.payload }
        }

        case 'VERIFY_EMAIL' : {
            return { ...state, verifyRegUserReq: action.payload }
        }

        case 'VERIFY_REG_USER_PENDING' : {
            return { ...state, verifyRegUserState: 1 }
        }

        case 'VERIFY_REG_USER_FULFILLED': {
            return { ...state, verifyRegUserState: 2, verifyRegUserRes: action.payload }
        }

        case 'VERIFY_REG_USER_REJECTED' : {
            return { ...state, verifyRegUserState: 3, verifyRegUserError: action.payload }
        }

        case 'USER_PROFILE' : {
            return { ...state, userProfile: action.payload }
        }

        case 'NOT_MATCH_PASSWORD': {
            return { ...state, completeRegUserError: action.payload, completeRegUserState: 3 }
        }

        case 'COMPLETE_REG_USER_PENDING' : {
            return { ...state, completeRegUserState: 1 }
        }

        case 'COMPLETE_REG_USER_FULFILLED' : {
            return { ...state, completeRegUserState: 2, completeRegUserRes: action.payload }
        }

        case 'COMPLETE_REG_USER_REJECTED' : {
            return { ...state, completeRegUserState: 3, completeRegUserError: action.payload }
        }

        case 'SET_AUTH_FOLLOW'  : {
            return { ...state, authFollow: action.payload }
        }

        case 'BEGIN_RESET_PASSWORD_PENDING' : {
            return { ...state, beginResetPasswordState: 1 }
        }

        case 'BEGIN_RESET_PASSWORD_FULFILLED' : {
            return { ...state, beginResetPasswordState: 2, beginResetPasswordRes: action.payload.beginResetPassword }
        }

        case 'BEGIN_RESET_PASSWORD_REJECTED' : {
            return { ...state, beginResetPasswordState: 3, beginResetPasswordError: action.payload }
        }
        case 'OTP_VERIFY_PASSWORD_PENDING' : {
            return { ...state, otpVerifyResetPasswordState: 1 }
        }

        case 'OTP_VERIFY_PASSWORD_FULFILLED' : {
            return { ...state, otpVerifyResetPasswordState: 2, otpVerifyResetPasswordRes: action.payload.beginResetPasswordRes}
        }

        case 'OTP_VERIFY_PASSWORD_REJECTED' : {
            return { ...state, otpVerifyResetPasswordState: 3, otpVerifyResetPasswordError: action.payload }
        }
        case 'SET_OTP_CODE' : {
            return { ...state, optCode: action.payload }
        }
        case 'COMPLETE_RESET_PASSWORD_PENDING' : {
            return { ...state, completeResetPasswordState: 1 }
        }

        case 'COMPLETE_RESET_PASSWORD_FULFILLED' : {
            return { ...state, completeResetPasswordState: 2, completeResetPasswordRes: action.payload }
        }

        case 'COMPLETE_RESET_PASSWORD_REJECTED' : {
            return { ...state, completeResetPasswordState: 3, completeResetPasswordError: action.payload }
        }

        case 'LOGIN_GOOGLE_USER_PENDING' : {
            return { ...state, oAuthLoginState: 1 }
        }

        case 'LOGIN_GOOGLE_USER_FULFILLED' : {
            const token = action.payload.loginGoogleUser
            storeToken(token, state.rememberMe)
            return { ...state, oAuthLoginState: 2, loginGoogleUserRes: action.payload }
        }

        case 'LOGIN_GOOGLE_USER_REJECTED' : {
            return { ...state, oAuthLoginState: 3, loginUserError: action.payload }
        }

        case 'LOGIN_FACEBOOK_USER_PENDING' : {
            return { ...state, oAuthLoginState: 1 }
        }

        case 'LOGIN_FACEBOOK_USER_FULFILLED' : {
            const token = action.payload.loginFacebookUser
            storeToken(token, state.rememberMe)
            return { ...state, oAuthLoginState: 2, loginFacebookUserRes: action.payload }
        }

        case 'LOGIN_FACEBOOK_USER_REJECTED' : {
            return { ...state, oAuthLoginState: 3, loginUserError: action.payload }
        }

        case 'CHECK_USERNAME_PENDING' : {
            return { ...state, checkUsernameState: 1 }
        }

        case 'CHECK_USERNAME_FULFILLED' : {
            return { ...state, checkUsernameState: 2, checkUsername: action.payload.checkUsername }
        }

        case 'CHECK_USERNAME_REJECTED' : {
            return { ...state, checkUsernameState: 3, checkUsernameError: action.payload }
        }

        case 'SET_REMEMBER_ME' :
            return { ...state, rememberMe: action.payload }

        case 'SET_BEGIN_REG_USER_STATE':
            return { ...state, beginRegUserState: action.payload }

        case 'SET_BEGIN_RESET_PASSWORD_STATE':
            return { ...state, beginResetPasswordState: action.payload }

        case 'RESET_LOGIN_STATE' :
            return {...state,loginState: action.payload}

        default:
            return state
    }
}

export default reducer
